/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-213956023",
  status: "https://stats.uptimerobot.com/lLkYps1klE"
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Daniel",
  logo_name: "mbd.one()",
  full_name: "Daniel",
  subTitle: "A Developer, Open Source Enthusiast 🔥. Always learning.",
  mail: "mailto:daniel@mbd.one",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/mbdoff",
  gmail: "daniel@mbd.one",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "🎗️ I develop very good apps that I am proud of.",
        "🧩 I like to insert easter eggs in my apps.",
        "📱 Developing mobile applications using Flutter and Android.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Visual Basic",
          fontAwesomeClassname: "simple-icons:dot-net",
          style: {
            color: "#029FCE",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "Design",
      fileName: "DesignImg",
      skills: [
        "📷 Editing photos/videos.",
        "📺 Making videos and product ads.",
      ],
      softwareSkills: [
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#31A8FF",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF9A00",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF61F6",
          },
        },
        {
          skillName: "Adobe Premiere Pro",
          fontAwesomeClassname: "simple-icons:adobepremierepro",
          style: {
            color: "#9999FF",
          },
        },
        {
          skillName: "Adobe After Effects",
          fontAwesomeClassname: "simple-icons:adobeaftereffects",
          style: {
            color: "#9999FF",
          },
        },
      ],
    },
  ],
};

// Progress Page
const progress = {
  title: "Progress",
  subtitle: "What, When and How",
  description:
    "Here you can see what projects I started, when and how I did them.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Year 2021",
      progress: [
        {
          title: "TerraCraft Project",
          subtitle: "BigFishHunterX & MBDOFF_",
          company_url: "https://terracraft.one",
          logo_path: "tc.png",
          duration: "Aug 2021 - Present",
          location: "Work From Home",
          description: ` I started working to a minecraft community which was closed more than 5 years ago, with one of the former founders BigFishHunterX. The main idea is to bring unique gameplays so that we can revive the community and make it shine like it used to.`,
          color: "#40b337",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description: "Here is everything I did and what can be seen by others.",
  avatar_image_path: "projects_image.svg",
};

const projects = {
  data: [
    {
      id: "0",
      name: "TerraCraft",
      url: "https://terracraft.one",
      description:
        "This is my minecraft community. It uses many custom made plugins for a much better and unique experience.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Minecraft",
          iconifyClass: "mdi:minecraft",
        },
        {
          name: "Php",
          iconifyClass: "logos-php",
        },
      ],
    },
    {
      id: "1",
      name: "Soon Page",
      url: "https://soon.mbd.one",
      description:
        "This is my official coming soon page.",
      languages: [
        {
          name: "Php",
          iconifyClass: "logos-php",
        },
        {
          name: "Html",
          iconifyClass: "logos-html-5",
        },
        {
          name: "Css3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "2",
      name: "GO! Links",
      url: "https://go.mbd.one",
      description:
        "This is my official link shortener app.",
      languages: [
        {
          name: "Html",
          iconifyClass: "logos-html-5",
        },
        {
          name: "Css3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "NextJS",
          iconifyClass: "cib:next-js"
        },
        {
          name: "ReactJS",
          iconifyClass: "logos:react"
        }
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  progress,
  projectsHeader,
  projects,
};
